<template>
  <div id="property-batch-upload">
    <div class="controls">
      <div class="title">
        Uploading properties for <strong>{{ company.human_name }}</strong>
      </div>
    </div>

    <div class="template">
      <a href="https://drive.google.com/file/d/1t4rFNJQCt4h-J4h2kN4-z3lpJkhIfkFx/view?usp=sharing" target="_blank">
        <el-button type="info" size="medium">
          Template
        </el-button>
      </a>
    </div>

    <batch-upload :company="company" />
  </div>
</template>

<script>
import BatchUpload from './_components/BatchUpload'

export default {
  name: 'PropertyBatchUpload',
  components: {
    'batch-upload': BatchUpload
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  align-items: center;
}
.template {
  margin-top: 1em;
}
</style>

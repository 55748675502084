<template>
  <div id="property-upload">
    <div class="upload-container">
      <el-upload
        ref="upload"
        action=""
        accept=".xlsx"
        :show-file-list="false"
        :auto-upload="false"
        :limit="1"
        :on-change="handleFileUpload"
        :on-remove="handleFileRemove"
      >
        <el-button type="primary" @click="handleFileRemove">
          Upload XLSX
        </el-button>
      </el-upload>

      <el-button
        v-if="hasFile"
        v-loading="loading"
        type="text"
        class="reset-button"
        @click="handleFileRemove"
      >
        Reset
      </el-button>
    </div>

    <div v-if="errors.length" class="error">
      <strong>The following errors were found while parsing the spreadsheet:</strong>
      <ul v-for="error in errors" :key="error">
        <li>{{ error }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import regex from '@/utils/regex'
import { enums, htConstants as constants } from '@/utils/constants'
import { isEmptyAddress, isValidAddress } from '@/utils/rooof'
import { filterObjectByKey, dateToString, csvStringToArray, isString, isDate, isNumeric } from '@/utils'

const VALIDATORS = {
  URL: {
    validate: value => {
      if (!value) {
        return true
      }
      return regex.url.test(value)
    },
    message: 'Invalid URL'
  },
  EMAIL: {
    validate: value => {
      if (!value) {
        return true
      }
      return regex.email.test(value)
    },
    message: 'Invalid email address'
  },
  JSON: {
    validate: value => {
      if (!value) {
        return true
      }
      try {
        JSON.parse(value)
      } catch (err) {
        return false
      }
      return true
    },
    message: 'Invalid JSON'
  },
  MAX_LENGTH: limit => {
    return {
      validate: value => {
        if (!value) {
          return true
        }
        return value.toString().length <= limit
      },
      message: `Value cannot exceed ${limit} characters`
    }
  },
  MIN_LENGTH: limit => {
    return {
      validate: value => {
        if (!value) {
          return true
        }
        return value.toString().length >= limit
      },
      message: `Value must be at least ${limit} characters`
    }
  },
  MAX_VALUE: limit => {
    return {
      validate: value => {
        if (!value) {
          return true
        }
        return value <= limit
      },
      message: `Value cannot exceed ${limit}`
    }
  },
  MIN_VALUE: limit => {
    return {
      validate: value => {
        if (!value) {
          return true
        }
        return value >= limit
      },
      message: `Value must be greater than ${limit}`
    }
  },
  RANGE: (min, max) => {
    return {
      validate: value => {
        if (!value) {
          return true
        }
        return value >= min && value <= max
      },
      message: `Value must be in the range ${min} - ${max}`
    }
  },
  CHOICES: choices => {
    return {
      validate: value => {
        if (!value) {
          return true
        }
        return choices.includes(value)
      }
    }
  }
}
const PROPERTY_COLUMNS = [
  // General
  { name: 'name', type: String, validators: [ VALIDATORS.MAX_LENGTH(128) ], required: true },
  { name: 'legal_name', type: String, validators: [ VALIDATORS.MAX_LENGTH(128) ] },
  { name: 'website_url', type: String, validators: [ VALIDATORS.URL, VALIDATORS.MAX_LENGTH(2000) ] },
  { name: 'floor_plan_urls', type: Array, validators: [ VALIDATORS.URL, VALIDATORS.MAX_LENGTH(2000) ] },
  { name: 'all_available_units_url', type: String, validators: [ VALIDATORS.URL, VALIDATORS.MAX_LENGTH(2000) ] },
  { name: 'type', type: String, validators: [ VALIDATORS.CHOICES(constants.propertyTypes) ] },
  { name: 'unit_count', type: Number, validators: [ VALIDATORS.MIN_VALUE(0) ] },
  // Contact
  { name: 'email_contact', type: String, validators: [ VALIDATORS.EMAIL ] },
  { name: 'email_leasing', type: String, validators: [ VALIDATORS.EMAIL ] },
  { name: 'email_agency', type: String, validators: [ VALIDATORS.EMAIL ] },
  { name: 'email_agency_decisionmaker', type: Array, validators: [ VALIDATORS.EMAIL ] },
  { name: 'phone_contact', type: 'StringOrNumber', validators: [ VALIDATORS.MAX_LENGTH(31) ] },
  { name: 'phone_leasing', type: 'StringOrNumber', validators: [ VALIDATORS.MAX_LENGTH(31) ] },
  { name: 'phone_callrail', type: 'StringOrNumber', validators: [ VALIDATORS.MAX_LENGTH(31) ] },
  { name: 'property_address:street', type: String, validators: [ VALIDATORS.MAX_LENGTH(300) ], required: true },
  { name: 'property_address:city', type: String, validators: [ VALIDATORS.MAX_LENGTH(100) ], required: true },
  { name: 'property_address:state', type: String, validators: [ VALIDATORS.MAX_LENGTH(2) ], required: true },
  { name: 'property_address:country', type: String, validators: [ VALIDATORS.MAX_LENGTH(100) ], required: true },
  { name: 'property_address:postal', type: 'StringOrNumber', validators: [ VALIDATORS.MAX_LENGTH(16) ], required: true },
  { name: 'property_address:latitude', type: Number, validators: [ VALIDATORS.RANGE(-90, 90), VALIDATORS.MAX_LENGTH(10) ] },
  { name: 'property_address:longitude', type: Number, validators: [ VALIDATORS.RANGE(-180, 180), VALIDATORS.MAX_LENGTH(10) ] },
  // Billing
  // { name: 'products', type: Array },
  // { name: 'features', type: Array },
  { name: 'billing_email', type: String, validators: [ VALIDATORS.EMAIL ] },
  { name: 'billing_address:street', type: String, validators: [ VALIDATORS.MAX_LENGTH(300) ], required: true },
  { name: 'billing_address:city', type: String, validators: [ VALIDATORS.MAX_LENGTH(100) ], required: true },
  { name: 'billing_address:state', type: String, validators: [ VALIDATORS.MAX_LENGTH(2) ], required: true },
  { name: 'billing_address:country', type: String, validators: [ VALIDATORS.MAX_LENGTH(100) ], required: true },
  { name: 'billing_address:postal', type: 'StringOrNumber', validators: [ VALIDATORS.MAX_LENGTH(16) ], required: true },
  { name: 'billing_address:latitude', type: Number, validators: [ VALIDATORS.RANGE(-90, 90), VALIDATORS.MAX_LENGTH(10) ] },
  { name: 'billing_address:longitude', type: Number, validators: [ VALIDATORS.RANGE(-180, 180), VALIDATORS.MAX_LENGTH(10) ] },
  { name: 'zoho_customer_id', type: Number },
  // Email Handler
  { name: 'email_delivery_lead', type: String, validators: [ VALIDATORS.EMAIL ] },
  { name: 'email_delivery_lead_copy', type: String, validators: [ VALIDATORS.EMAIL ] },
  { name: 'email_delivery_property', type: String, validators: [ VALIDATORS.EMAIL ] },
  // Other
  { name: 'crm_id', type: 'StringOrNumber' },
  { name: 'knock_community_id', type: 'StringOrNumber', validators: [ VALIDATORS.MAX_LENGTH(100) ] },
  { name: 'post_update_id', type: 'StringOrNumber', validators: [ VALIDATORS.MAX_LENGTH(100) ] },
  { name: 'notes', type: String },
  { name: 'property:extra', type: String, validators: [ VALIDATORS.JSON ] },
  { name: 'short_url_slug', type: String },
  { name: 'short_url_target', type: String, validators: [ VALIDATORS.URL, VALIDATORS.MAX_LENGTH(2000) ] }
]
const POSTING_COLUMNS = [
  // Site details
  { name: 'posting_category', type: String, validators: [ VALIDATORS.CHOICES(constants.postingCategory) ] },
  { name: 'rent_period', type: String, validators: [ VALIDATORS.CHOICES(constants.rentPeriod) ] },
  { name: 'major_region', type: String },
  { name: 'sub_region', type: String },
  { name: 'sub_sub_region', type: String },
  { name: 'email_policy', type: String, validators: [ VALIDATORS.CHOICES(constants.emailPolicy) ] },
  // Ad details
  { name: 'dog_policy', type: Boolean },
  { name: 'cat_policy', type: Boolean },
  { name: 'smoking_policy', type: String, validators: [ VALIDATORS.CHOICES(Object.keys(enums.smokingPolicy)) ] },
  { name: 'wheelchair_access', type: Boolean },
  { name: 'furnished', type: Boolean },
  { name: 'ev_charging', type: Boolean },
  { name: 'air_conditioning', type: Boolean },
  { name: 'laundry', type: String, validators: [ VALIDATORS.CHOICES(constants.laundry) ] },
  { name: 'parking', type: String, validators: [ VALIDATORS.CHOICES(constants.parking) ] },
  // Ad content
  { name: 'location', type: Array, validators: [ VALIDATORS.MAX_LENGTH(128) ] },
  { name: 'amenities_property', type: Array, validators: [ VALIDATORS.MAX_LENGTH(1000) ] },
  { name: 'amenities_unit', type: Array, validators: [ VALIDATORS.MAX_LENGTH(1000) ] },
  { name: 'romance_text', type: Array, validators: [ VALIDATORS.MAX_LENGTH(5000) ] },
  { name: 'header', type: Array, validators: [ VALIDATORS.MAX_LENGTH(1000) ] },
  { name: 'footer', type: Array, validators: [ VALIDATORS.MAX_LENGTH(1000) ] },
  { name: 'disclaimer', type: Array, validators: [ VALIDATORS.MAX_LENGTH(1000) ] },
  { name: 'posting:extra', type: String, validators: [ VALIDATORS.JSON ] }
]
const COLUMNS = PROPERTY_COLUMNS.concat(POSTING_COLUMNS)

export default {
  name: 'PropertyUpload',
  data () {
    return {
      loading: false,
      errors: [],
      hasFile: false
    }
  },
  methods: {
    /**
     * onChange handler for file upload input.
     *
     * @param {Object} file - uploaded file object
     * @param {Array} fileList - list of uploaded files
     */
    handleFileUpload (file, fileList) {
      this.handleFileRemove()

      if (file) {
        this.loading = true
        const fileReader = new FileReader()

        fileReader.onload = event => {
          const options = {
            type: 'binary',
            blankrows: false,
            cellDates: true
          }
          const workbook = XLSX.read(event.target.result, options)
          const sheetNameList = workbook.SheetNames

          let data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]])

          if (!data.length) {
            this.errors = ['Spreadsheet must contain at least one row of data']
            return
          }

          data = this.sanitize(data)

          const valid = this.validate(data)
          if (valid) {
            data = this.format(data)
            this.$emit('upload', data)
            this.hasFile = true
          }
        }
        fileReader.readAsBinaryString(file.raw)
        this.loading = false
      }
    },
    /**
     * onRemove handler for file upload input.
     *
     * @param {Object} file - uploaded file object
     * @param {Array} fileList - list of uploaded files
     */
    handleFileRemove (file, fileList) {
      this.$refs['upload'].clearFiles()
      this.$emit('upload', [])
      this.errors = []
      this.hasFile = false
    },
    /**
     * Sanitize the uploaded data to only allow specific keys.
     *
     * @param {Object} data
     * @returns {Array}
     */
    sanitize (data) {
      const ALLOWED_COLUMNS = PROPERTY_COLUMNS.concat(POSTING_COLUMNS).map(el => el.name)

      return data.map(property => {
        return filterObjectByKey(property, ALLOWED_COLUMNS)
      })
    },
    /**
     * Validate the parsed xlsx data.
     *
     * By default, xlsx will attempt to coerce cell data into
     * its appropriate javascript type.
     *
     * @param {Array} data - raw json data
     * @returns {Boolean} - true if valid, else false
     */
    validate (data) {
      const errors = []
      const names = {}

      for (const row of data) {
        for (const col of COLUMNS) {
          // Is this column required?
          if (col.required && !row[col.name]) {
            errors.push(`${row.name} is missing a required column (${col.name})`)
            continue
          }

          if (row[col.name]) {
            const value = row[col.name]

            // Validate spreadsheet column type matches the specified type
            switch (col.type) {
              case String:
                if (!isString(value)) {
                  errors.push(`${row.name} has an invalid value for column '${col.name}' (text required)`)
                }
                break
              case Number:
                if (!isNumeric(value)) {
                  errors.push(`${row.name} has an invalid value for column '${col.name}' (number required)`)
                }
                break
              case Date:
                if (!isDate(value)) {
                  errors.push(`${row.name} has an invalid value for column '${col.name}' (date required)`)
                }
                break
              case Boolean:
                if (!(value.toLowerCase() !== 'yes' || value.toLowerCase() !== 'no')) {
                  errors.push(`${row.name} has an invalid value for column '${col.name}' (yes/no required)`)
                }
                break
              case 'StringOrNumber':
                if (!(isString(value) || isNumeric(value))) {
                  errors.push(`${row.name} has an invalid value for column '${col.name}' (text or number required)`)
                }
                break
              case Array:
                break // do nothing, arrays are read in as strings
              default:
                errors.push(`Invalid column type: ${col.type}`)
            }

            // Run validators
            if (col.validators && col.validators.length > 0) {
              if (col.type === Array) {
                const list = csvStringToArray(value)

                for (const validator of col.validators) {
                  if (!list.every(el => validator.validate(el))) {
                    errors.push(`${row.name} has an invalid value for column '${col.name}' (${validator.message})`)
                  }
                }
              } else {
                for (const validator of col.validators) {
                  if (!validator.validate(value)) {
                    errors.push(`${row.name} has an invalid value for column '${col.name}' (${validator.message})`)
                  }
                }
              }
            }
          }
        }

        // Validate address fields
        if (!this.validateAddress(row, 'billing_address')) {
          errors.push(`${row.name} has an invalid billing_address`)
        }
        if (!this.validateAddress(row, 'property_address')) {
          errors.push(`${row.name} has an invalid property_address`)
        }

        // Keep track of duplicate names
        if (row.hasOwnProperty('name')) {
          const propertyName = row.name.toLowerCase()
          if (names.hasOwnProperty(propertyName)) {
            names[propertyName]++
          } else {
            names[propertyName] = 1
          }
        }
      }
      const duplicates = Object.keys(names).filter(key => names[key] > 1)
      if (duplicates.length) {
        errors.push(`Duplicate property names detected: ${duplicates}`)
      }

      if (errors.length) {
        this.errors = errors
        return false
      }
      return true
    },
    /**
     * Format the parsed data.
     *
     * Some of the formatting will be handled by the serializer
     * during the create request.
     *
     * @param {Array} data
     * @returns {Array}
     */
    format (data) {
      return data.map(property => {
        for (const col of COLUMNS) {
          if (property[col.name]) {
            // Convert date objects to string representation
            if (col.type === Date) {
              property[col.name] = dateToString(property[col.name])
            }
            // Convert csv strings into lists
            if (col.type === Array) {
              property[col.name] = csvStringToArray(property[col.name])
            }
          }
        }

        // Nest Rooof.Property fields
        property.property = {}
        PROPERTY_COLUMNS.forEach(col => {
          if (property.hasOwnProperty(col.name)) {
            property.property[col.name] = property[col.name]
            delete property[col.name]
          }
        })

        // Format address fields
        property.property.billing_address = this.getFormattedAddress(property.property, 'billing_address')
        property.property.property_address = this.getFormattedAddress(property.property, 'property_address')
        this.deleteOldAddressFields(property.property, 'billing_address')
        this.deleteOldAddressFields(property.property, 'property_address')

        // Format smoking_policy
        if (property['smoking_policy'] && Object.keys(enums.smokingPolicy).includes(property['smoking_policy'])) {
          property.smoking_policy = enums.smokingPolicy[property['smoking_policy']]
        }
        // Format 'extra' fields
        if (property.property['property:extra']) {
          property.property.extra = property.property['property:extra']
          delete property.property['property:extra']
        }
        if (property['posting:extra']) {
          property.extra = property['posting:extra']
          delete property['posting:extra']
        }

        // If property has email_contact filled but not email_agency, copy the value over
        if (property.property['email_contact'] && !property.property['email_agency']) {
          property.property.email_agency = property.property.email_contact
        }

        return property
      })
    },
    /**
     * Validate the address fields as they appear in
     * the uploaded spreadsheet.
     *
     * @param {Object} property - Rooof.Property object
     * @param {String} fieldName - address type
     * @returns {Boolean}
     */
    validateAddress (property, fieldName) {
      const address = this.getFormattedAddress(property, fieldName)
      if (!isEmptyAddress(address) && !isValidAddress(address)) {
        return false
      }
      return true
    },
    /**
     * Normalize address fields into format expected by api.
     *
     * @param {Object} property - Rooof.Property object
     * @param {String} type - address type
     * @returns {Object} - address object
     */
    getFormattedAddress (property, type) {
      const state = property[`${type}:state`] || ''

      return {
        street: property[`${type}:street`] || '',
        city: property[`${type}:city`] || '',
        state: state.toUpperCase(),
        country: property[`${type}:country`] || '',
        postal: property[`${type}:postal`] || '',
        latitude: property[`${type}:latitude`] || null,
        longitude: property[`${type}:longitude`] || null
      }
    },
    /**
     * Remove the address fields used in the spreadsheet.
     * Modifies the given property object directly.
     *
     * @param {Object} property - Rooof.Property object
     * @param {String} type - address type
     */
    deleteOldAddressFields (property, type) {
      delete property[`${type}:street`]
      delete property[`${type}:city`]
      delete property[`${type}:state`]
      delete property[`${type}:country`]
      delete property[`${type}:postal`]
      delete property[`${type}:latitude`]
      delete property[`${type}:longitude`]
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container {
  display: flex;
  .reset-button {
    margin-left: 10px;
  }
}
.error {
  margin-top: 1em;
  padding: 0.5em;
  border-radius: 4px;
  background-color: #ff7d7d;
  color: #fff;
}
</style>

<template>
  <div id="batch-upload">
    <h3>Select a spreadsheet (in XLSX format) to import</h3>

    <div class="info">
      Fore more information on how to use this feature:
      <a href="https://rooofdotcom.atlassian.net/wiki/spaces/SA/pages/2305490945/Using+Property+Upload+to+batch+import+propertiesa" target="_blank">Using Property Batch Upload</a>
    </div>

    <el-row>
      <el-col>
        <property-upload @upload="handleUpload" />
      </el-col>
    </el-row>

    <div v-if="data.length">
      <h3>Nice work! Here's your data:</h3>

      <property-table :data="data" />

      <el-button
        type="success"
        :loading="loading"
        @click="submit()"
      >
        {{ loading ? 'Uploading...' : 'Looks good, submit' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import PropertyUpload from './Upload'
import PropertyTable from './Table'

import RooofAPI from '@/services/api/rooof'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'BatchUpload',
  components: {
    'property-upload': PropertyUpload,
    'property-table': PropertyTable
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: [],
      loading: false
    }
  },
  methods: {
    /**
     * Event handler for 'upload' event.
     *
     * @param {Array} result - spreadsheet data parsed into json
     */
    handleUpload (result) {
      this.data = result
    },
    /**
     * Generate create requests and send to server.
     */
    async submit () {
      this.loading = true
      let requests = []

      for (const property of this.data) {
        // Create the Rooof.Property objects (this will also
        // create Craigslist.CraigslistProperty objects)
        property.property.groups = [ this.company.name ]
        requests.push(RooofAPI.properties.create(property.property))
      }

      try {
        const properties = await Promise.all(requests)
        requests = []

        for (const propertyData of properties) {
          const property = this.data.find(el => el.property.name === propertyData.name)
          requests.push(CraigslistAPI.properties.update(propertyData.id, property))
        }

        await Promise.all(requests)

        this.done()
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
      this.loading = false
    },
    /**
     * Display success message and navigate user to previous view.
     */
    done () {
      this.$router.push({ name: 'CompanyOverview' })
      this.$message({
        message: 'Properties created',
        type: 'success',
        duration: 3500
      })
    }
  }
}
</script>

<style scoped>
.info {
  margin-bottom: 2em;
  font-size: 14px;
}
</style>

<template>
  <div id="property-table">
    <el-table
      :data="data"
      height="600px"
      size="mini"
      border
    >
      <!-- property fields -->
      <el-table-column
        type="index"
        width="50"
        fixed
      />

      <el-table-column
        prop="property.name"
        label="Name"
        width="150"
        fixed
      />
      <el-table-column
        prop="property.legal_name"
        label="Legal Name"
        width="150"
      />
      <el-table-column
        prop="property.website_url"
        label="Website URL"
        width="150"
      >
        <template slot-scope="scope">
          <a :href="scope.row.property.website_url" target="_blank">
            {{ scope.row.property.website_url }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="property.floor_plan_urls"
        label="Floor Plan URL(s)"
        width="200"
      >
        <template slot-scope="scope">
          <div v-for="url in scope.row.property.floor_plan_urls" :key="url">
            <a :href="url" target="_blank">
              {{ url }}
            </a>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="property.all_available_units_url"
        label="All Available Units URL"
        width="200"
      >
        <template slot-scope="scope">
          <a :href="scope.row.property.all_available_units_url" target="_blank">
            {{ scope.row.property.all_available_units_url }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="property.type"
        label="Type"
        width="150"
      />
      <el-table-column
        prop="property.unit_count"
        label="Unit Count"
        width="150"
      />

      <el-table-column
        prop="property.email_contact"
        label="Property Email"
        width="150"
      />
      <el-table-column
        prop="property.email_leasing"
        label="Lead Email"
        width="150"
      />
      <el-table-column
        prop="property.email_agency"
        label="Full Service Contact Email"
        width="150"
      />
      <el-table-column
        prop="property.email_agency_decisionmaker"
        label="Full Service DM Email"
        width="150"
      />
      <el-table-column
        prop="property.email_manager"
        label="Manager Email"
        width="150"
      />
      <el-table-column
        prop="property.phone_contact"
        label="Property Phone"
        width="150"
      />
      <el-table-column
        prop="property.phone_leasing"
        label="Lead Phone"
        width="150"
      />
      <el-table-column
        prop="property.phone_callrail"
        label="CallRail Phone"
        width="150"
      />
      <el-table-column
        prop="property.property_address"
        label="Property Address"
        width="200"
      >
        <template slot-scope="scope">
          <pre>{{ formatAddress(scope.row.property.property_address) }}</pre>
        </template>
      </el-table-column>

      <el-table-column
        prop="property.product_subscriptions"
        label="Product Subscriptions"
        width="150"
      />
      <el-table-column
        prop="property.feature_subscriptions"
        label="Feature Subscriptions"
        width="150"
      />
      <el-table-column
        prop="property.billing_address"
        label="Billing Address"
        width="150"
      >
        <template slot-scope="scope">
          <pre>{{ formatAddress(scope.row.property.billing_address) }}</pre>
        </template>
      </el-table-column>
      <el-table-column
        prop="property.zoho_customer_id"
        label="ZOHO Customer ID"
        width="150"
      />

      <el-table-column
        prop="property.email_delivery_lead"
        label="Email Delivery (lead)"
        width="200"
      />
      <el-table-column
        prop="property.email_delivery_lead_copy"
        label="Email Delivery (lead copy)"
        width="200"
      />
      <el-table-column
        prop="property.email_delivery_property"
        label="Email Delivery (property)"
        width="200"
      />

      <el-table-column
        prop="property.crm_id"
        label="CRM ID"
        width="150"
      />
      <el-table-column
        prop="property.post_update_id"
        label="Post Update ID"
        width="150"
      />
      <el-table-column
        prop="property.knock_community_id"
        label="Knock Community ID"
        width="150"
      />
      <el-table-column
        prop="property.notes"
        label="Notes"
        width="150"
      />
      <el-table-column
        prop="property.extra"
        label="Custom Fields (property)"
        width="200"
      >
        <template slot-scope="scope">
          <pre>{{ formatJSON(scope.row.property.extra) }}</pre>
        </template>
      </el-table-column>

      <!-- posting fields -->
      <!-- <el-table-column
        prop="phone_number"
        label="Phone Number"
        width="150"
      />
      <el-table-column
        prop="phone_extension"
        label="Phone Extension"
        width="150"
      />
      <el-table-column
        prop="contact_by_phone"
        label="Accept Phone Calls"
        width="150"
      />
      <el-table-column
        prop="contact_by_text"
        label="Accept Text Messages"
        width="150"
      />
      <el-table-column
        prop="contact_name"
        label="Contact Name"
        width="150"
      /> -->
      <el-table-column
        prop="posting_category"
        label="Posting Category"
        width="150"
      />
      <el-table-column
        prop="rent_period"
        label="Rent Period"
        width="150"
      />
      <el-table-column
        prop="dog_policy"
        label="Dog Policy"
        width="150"
      />
      <el-table-column
        prop="cat_policy"
        label="Cat Policy"
        width="150"
      />
      <el-table-column
        prop="smoking_policy"
        label="Smoking Policy"
        width="150"
      >
        <template slot-scope="scope">
          <pre>{{ getConstantLabelByValue('smokingPolicy', scope.row.smoking_policy) }}</pre>
        </template>
      </el-table-column>
      <el-table-column
        prop="housing_type"
        label="Housing Type"
        width="150"
      />
      <el-table-column
        prop="wheelchair_access"
        label="Wheelchair Access"
        width="150"
      />
      <el-table-column
        prop="furnished"
        label="Furnished"
        width="150"
      />
      <el-table-column
        prop="laundry"
        label="Laundry"
        width="150"
      />
      <el-table-column
        prop="parking"
        label="Parking"
        width="150"
      />
      <el-table-column
        prop="air_conditioning"
        label="Air Conditioning"
        width="150"
      />
      <el-table-column
        prop="ev_charging"
        label="EV Charging"
        width="150"
      />
      <el-table-column
        prop="major_region"
        label="Major Region"
        width="150"
      />
      <el-table-column
        prop="sub_region"
        label="Sub Region"
        width="150"
      />
      <el-table-column
        prop="sub_sub_region"
        label="Sub Sub Region"
        width="150"
      />
      <el-table-column
        prop="location"
        label="Location"
        width="150"
        show-overflow-tooltip
      />
      <el-table-column
        prop="amenities_property"
        label="Amenities (property)"
        width="150"
        show-overflow-tooltip
      />
      <el-table-column
        prop="amenities_unit"
        label="Amenities (unit)"
        width="150"
        show-overflow-tooltip
      />
      <el-table-column
        prop="title_setting"
        label="Title Behavior"
        width="150"
      />
      <el-table-column
        prop="title"
        label="Title"
        width="150"
        show-overflow-tooltip
      />
      <el-table-column
        prop="romance_text"
        label="Romance Text"
        width="150"
        show-overflow-tooltip
      />
      <el-table-column
        prop="header"
        label="Header(s)"
        width="150"
        show-overflow-tooltip
      />
      <el-table-column
        prop="footer"
        label="Footer(s)"
        width="150"
        show-overflow-tooltip
      />
      <el-table-column
        prop="disclaimer"
        label="Disclaimers(s)"
        width="150"
        show-overflow-tooltip
      />
      <el-table-column
        prop="extra"
        label="Custom Fields (posting)"
        width="200"
      >
        <template slot-scope="scope">
          <pre>{{ formatJSON(scope.row.extra) }}</pre>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getConstantLabelByValue } from '@/utils/constants'
import { isEmptyAddress } from '@/utils/rooof'

export default {
  name: 'PropertyTable',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  created () {
    this.getConstantLabelByValue = getConstantLabelByValue
  },
  methods: {
    /**
     * Format address object for display in table.
     *
     * @param {Object} address
     */
    formatAddress (address) {
      if (!address || isEmptyAddress(address)) {
        return ''
      }
      const line1 = `${address.street}`
      const line2 = `${address.city}`
      const line3 = `${address.state}, ${address.country}`
      const line4 = `${address.postal}`

      return [line1, line2, line3, line4].join('\n')
    },
    /**
     * Format json for display in table.
     *
     * @param {(Object|String)} json
     * @returns {String}
     */
    formatJSON (json) {
      if (!json) {
        return ''
      }
      if (typeof json === 'object') {
        return JSON.stringify(json, null, 2)
      }
      return JSON.stringify(JSON.parse(json), null, 2)
    }
  }
}
</script>

<style scoped>
.el-table {
  margin-top: 1em;
  margin-bottom: 2em;
}
</style>
